@import "~antd/dist/antd.less";

body {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 2;
}

.table-responsive .ant-table-content {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.mb-2 {
  margin-bottom: 2rem;
}

.capitalize {
  text-transform: capitalize;
}

.ant-form-item-label label {
  white-space: pre-line;
}

.css-nxs594 {
  border-radius: 1000px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
  flex-shrink: 0;
  background: #a0aec0;
  color: #1a202c;
  border-color: #fff;
  vertical-align: top;
  width: 2.5rem;
  height: 2.5rem;
  font-size: calc(1.2rem);
}

.css-16ite8i {
  color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
}

@primary-color: #373075;